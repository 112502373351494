<template>
  <div :class="['ele-exception ele-text-center',{'ele-exception-dark':$store.state.theme.theme==='dark'}]">
    <div class="ele-exception-img">
      <svg width="422" height="193" viewBox="0 0 422 193" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path fill-opacity=".25" fill="#A3B1BF" opacity=".9" d="M218.562 13.895v114.572h109.58V13.895z"/>
          <path stroke="#A3B1BF" stroke-width="1.62" d="M218.562 13.895v114.572h109.58V13.895z"/>
          <path
              d="M322.202 12.485c0-4.562 3.747-8.295 8.327-8.295h12.539c4.58 0 8.326 3.733 8.326 8.295v117.488c0 4.562-3.747 8.295-8.326 8.295h-12.54c-4.58 0-8.326-3.733-8.326-8.295V12.485z"
              fill="#F0F2F5"/>
          <path
              d="M322.202 12.485c0-4.562 3.747-8.295 8.327-8.295h12.539c4.58 0 8.326 3.733 8.326 8.295v117.488c0 4.562-3.747 8.295-8.326 8.295h-12.54c-4.58 0-8.326-3.733-8.326-8.295V12.485z"
              stroke="#A3B1BF" stroke-width="1.62"/>
          <path
              d="M343.897 15.047a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49l26.491-16.73 13.386-7.278"/>
          <path
              d="M343.957 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M343.957 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M343.957 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M343.957 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.827 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M343.957 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.827 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M343.957 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.827 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M343.957 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.827 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M343.957 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M343.957 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M343.957 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.827 0 1.497.667 1.497 1.491 0 .824-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M343.957 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.827 0 1.497.667 1.497 1.491 0 .824-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M327.904 13.589c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49a1.494 1.494 0 0 1-1.496-1.49z"
              fill="#FFF"/>
          <path
              d="M329.4 15.08a1.494 1.494 0 0 1-1.496-1.491c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M329.4 24.004a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M329.4 24.004a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M329.4 32.927a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M329.4 32.927a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M329.4 41.851a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M329.4 41.851a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M329.4 50.775a1.494 1.494 0 0 1-1.496-1.491c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M329.4 50.775a1.494 1.494 0 0 1-1.496-1.491c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M329.4 59.698a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.491 0 .824-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M329.4 59.698a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.491 0 .824-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M335.182 17.645c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491a1.494 1.494 0 0 1-1.497-1.49z"
              fill="#FFF"/>
          <path
              d="M336.679 19.136a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M336.679 28.06a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M336.679 28.06a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M336.679 36.984a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M336.679 36.984a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M336.679 45.907a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M336.679 45.907a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M336.679 54.83a1.494 1.494 0 0 1-1.497-1.49c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M336.679 54.83a1.494 1.494 0 0 1-1.497-1.49c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M336.679 63.755a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M336.679 63.755a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M332.747 122.705c0-2.39 1.944-4.326 4.342-4.326 2.399 0 4.343 1.937 4.343 4.326 0 2.388-1.944 4.325-4.343 4.325-2.398 0-4.342-1.937-4.342-4.325z"
              fill="#A3B1BF"/>
          <path
              d="M308.374 114.052a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#F5F5F5"/>
          <path
              d="M308.374 114.052a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M294.626 114.052a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#F5F5F5"/>
          <path
              d="M294.626 114.052a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M308.374 31.305a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#F5F5F5"/>
          <path
              d="M308.374 31.305a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M294.626 31.305a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#F5F5F5"/>
          <path
              d="M294.626 31.305a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M251.844 12.485c0-4.562 3.747-8.295 8.327-8.295h12.54c4.579 0 8.326 3.733 8.326 8.295v117.488c0 4.562-3.747 8.295-8.327 8.295h-12.539c-4.58 0-8.327-3.733-8.327-8.295V12.485z"
              fill="#F0F2F5"/>
          <path
              d="M251.844 12.485c0-4.562 3.747-8.295 8.327-8.295h12.54c4.579 0 8.326 3.733 8.326 8.295v117.488c0 4.562-3.747 8.295-8.327 8.295h-12.539c-4.58 0-8.327-3.733-8.327-8.295V12.485z"
              stroke="#A3B1BF" stroke-width="1.62"/>
          <path
              d="M273.6 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49"
              fill="#FFF"/>
          <path
              d="M273.6 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M273.6 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.492 0 .823-.67 1.49-1.496 1.49"
              fill="#FFF"/>
          <path
              d="M273.6 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M273.6 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492s-.67 1.491-1.496 1.491"
              fill="#FFF"/>
          <path
              d="M273.6 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492s-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M273.6 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492 0 .823-.67 1.491-1.496 1.491"
              fill="#FFF"/>
          <path
              d="M273.6 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492 0 .823-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M273.6 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49"
              fill="#FFF"/>
          <path
              d="M273.6 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M272.103 58.207c0-.824.67-1.491 1.497-1.491.826 0 1.496.667 1.496 1.491 0 .824-.67 1.491-1.496 1.491a1.494 1.494 0 0 1-1.497-1.49z"
              fill="#FFF"/>
          <path
              d="M273.6 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.491 0 .824-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M259.043 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M259.043 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M259.043 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M259.043 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M259.043 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M259.043 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M259.043 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M259.043 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M259.043 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M259.043 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M259.043 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.826 0 1.497.667 1.497 1.491 0 .824-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M259.043 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.826 0 1.497.667 1.497 1.491 0 .824-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M266.321 19.136a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M266.321 19.136a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M266.321 28.06a1.494 1.494 0 0 1-1.496-1.491c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M266.321 28.06a1.494 1.494 0 0 1-1.496-1.491c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M266.321 36.984a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M266.321 36.984a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M266.321 45.907a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491"
              fill="#FFF"/>
          <path
              d="M266.321 45.907a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M266.321 54.83a1.494 1.494 0 0 1-1.496-1.49c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M266.321 54.83a1.494 1.494 0 0 1-1.496-1.49c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M266.321 63.755a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"
              fill="#FFF"/>
          <path
              d="M266.321 63.755a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M261.58 122.705c0-2.39 1.945-4.326 4.343-4.326 2.398 0 4.343 1.937 4.343 4.326 0 2.388-1.945 4.325-4.343 4.325-2.398 0-4.342-1.937-4.342-4.325z"
              fill="#A3B1BF"/>
          <path
              d="M216.261 12.485c0-4.562 3.747-8.295 8.327-8.295h12.54c4.579 0 8.326 3.733 8.326 8.295v117.488c0 4.562-3.747 8.295-8.327 8.295h-12.539c-4.58 0-8.327-3.733-8.327-8.295V12.485z"
              fill="#F0F2F5"/>
          <path
              d="M216.261 12.485c0-4.562 3.747-8.295 8.327-8.295h12.54c4.579 0 8.326 3.733 8.326 8.295v117.488c0 4.562-3.747 8.295-8.327 8.295h-12.539c-4.58 0-8.327-3.733-8.327-8.295V12.485z"
              stroke="#A3B1BF" stroke-width="1.62"/>
          <path
              d="M238.017 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49"/>
          <path
              d="M238.017 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M238.017 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.492 0 .823-.67 1.49-1.496 1.49"/>
          <path
              d="M238.017 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M238.017 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492s-.67 1.491-1.496 1.491"/>
          <path
              d="M238.017 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492s-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M238.017 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492 0 .823-.67 1.491-1.496 1.491"/>
          <path
              d="M238.017 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492 0 .823-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M238.017 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49"/>
          <path
              d="M238.017 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke-opacity=".85" stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M238.017 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.491 0 .824-.67 1.491-1.496 1.491"/>
          <path
              d="M238.017 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.491 0 .824-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M224.269 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49"/>
          <path
              d="M224.269 15.08a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M224.269 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.492 0 .823-.67 1.49-1.496 1.49"/>
          <path
              d="M224.269 24.004a1.494 1.494 0 0 1-1.497-1.491c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M224.269 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492s-.67 1.491-1.496 1.491"/>
          <path
              d="M224.269 32.927a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492s-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M224.269 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492 0 .823-.67 1.491-1.496 1.491"/>
          <path
              d="M224.269 41.851a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.493 1.497-1.493.826 0 1.496.668 1.496 1.492 0 .823-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M224.269 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49"/>
          <path
              d="M224.269 50.775a1.494 1.494 0 0 1-1.497-1.491c0-.824.67-1.492 1.497-1.492.826 0 1.496.668 1.496 1.492 0 .823-.67 1.49-1.496 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M224.269 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.491 0 .824-.67 1.491-1.496 1.491"/>
          <path
              d="M224.269 59.698a1.494 1.494 0 0 1-1.497-1.49c0-.825.67-1.492 1.497-1.492.826 0 1.496.667 1.496 1.491 0 .824-.67 1.491-1.496 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M230.738 19.136a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491"/>
          <path
              d="M230.738 19.136a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492 0 .823-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M230.738 28.06a1.494 1.494 0 0 1-1.496-1.491c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"/>
          <path
              d="M230.738 28.06a1.494 1.494 0 0 1-1.496-1.491c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M230.738 36.984a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"/>
          <path
              d="M230.738 36.984a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M230.738 45.907a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491"/>
          <path
              d="M230.738 45.907a1.494 1.494 0 0 1-1.496-1.49c0-.825.67-1.493 1.496-1.493.827 0 1.497.668 1.497 1.492s-.67 1.491-1.497 1.491z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M230.738 54.83a1.494 1.494 0 0 1-1.496-1.49c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49"/>
          <path
              d="M230.738 54.83a1.494 1.494 0 0 1-1.496-1.49c0-.824.67-1.492 1.496-1.492.827 0 1.497.668 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M230.738 63.755a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49"/>
          <path
              d="M230.738 63.755a1.494 1.494 0 0 1-1.496-1.491c0-.825.67-1.492 1.496-1.492.827 0 1.497.667 1.497 1.492 0 .823-.67 1.49-1.497 1.49z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M231.149 127.03c-2.398 0-4.342-1.937-4.342-4.325 0-2.39 1.944-4.326 4.342-4.326 2.398 0 4.342 1.937 4.342 4.326 0 2.388-1.944 4.325-4.342 4.325v-6.665"
              fill="#A3B1BF"/>
          <path
              d="M153.138 11.025l11.888-3.975c4.342-1.452 9.086.9 10.544 5.224a17757910638.196 17757910638.196 0 0 0 37.532 111.381c1.458 4.326-.902 9.051-5.243 10.503l-11.888 3.975c-4.342 1.451-9.086-.899-10.544-5.225l-37.532-111.38c-1.458-4.325.902-9.051 5.243-10.503z"
              stroke="#A3B1BF" stroke-width="1.62"/>
          <path
              d="M171.093 27.043a1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887 1.498 1.498 0 0 1-1.896-.939z"/>
          <path
              d="M171.093 27.043a1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887 1.498 1.498 0 0 1-1.896-.939z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M175.415 36.906a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.896.939 1.49 1.49 0 0 1-.943 1.888"/>
          <path
              d="M173.52 35.967a1.489 1.489 0 0 1 .942-1.888 1.498 1.498 0 0 1 1.896.939 1.49 1.49 0 0 1-.943 1.888 1.498 1.498 0 0 1-1.896-.94z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M178.65 45.018a1.498 1.498 0 0 1-1.896-.939 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M176.754 44.08a1.489 1.489 0 0 1 .943-1.889 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887 1.498 1.498 0 0 1-1.896-.939z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M181.076 53.942a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M181.076 53.942a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M184.31 62.055a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.888"/>
          <path
              d="M184.31 62.055a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.888z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M186.737 70.978a1.498 1.498 0 0 1-1.896-.939 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M186.737 70.978a1.498 1.498 0 0 1-1.896-.939 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M169.754 32.85a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.888"/>
          <path
              d="M157.069 31.91a1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.888 1.498 1.498 0 0 1-1.895-.94z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M172.18 40.962a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .944-1.887 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M161.39 40.962a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M175.415 49.886a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.896.939 1.49 1.49 0 0 1-.943 1.888"/>
          <path
              d="M164.625 49.886a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.939 1.49 1.49 0 0 1-.943 1.888z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M167.328 57.998a1.498 1.498 0 0 1-1.896-.939 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M167.328 57.998a1.498 1.498 0 0 1-1.896-.939 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M170.562 66.922a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M170.562 66.922a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M173.797 75.035a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.888"/>
          <path
              d="M173.797 75.035a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.888z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M167.328 34.472a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M167.328 34.472a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M170.562 43.396a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.939 1.49 1.49 0 0 1-.943 1.888"/>
          <path
              d="M170.562 43.396a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.939 1.49 1.49 0 0 1-.943 1.888z"
              stroke-opacity=".85" stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M172.989 51.508a1.498 1.498 0 0 1-1.896-.939 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M172.989 51.508a1.498 1.498 0 0 1-1.896-.939 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M176.223 60.432a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M176.223 60.432a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M178.65 68.545a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.888"/>
          <path
              d="M178.65 68.545a1.498 1.498 0 0 1-1.896-.94 1.489 1.489 0 0 1 .943-1.888 1.498 1.498 0 0 1 1.896.94 1.49 1.49 0 0 1-.943 1.888z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M181.884 77.468a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.887"/>
          <path
              d="M181.884 77.468a1.498 1.498 0 0 1-1.895-.94 1.489 1.489 0 0 1 .943-1.887 1.498 1.498 0 0 1 1.895.94 1.49 1.49 0 0 1-.943 1.887z"
              stroke="#A3B1BF" stroke-width=".81"/>
          <path
              d="M198.567 126.807a4.347 4.347 0 0 1-5.5-2.725 4.32 4.32 0 0 1 2.736-5.477 4.346 4.346 0 0 1 5.499 2.724 4.322 4.322 0 0 1-2.735 5.478"
              fill="#A3B1BF"/>
          <path stroke="#A3B1BF" stroke-width=".81"
                d="M193.655 2.255l2.626-1.51 2.626 1.51v3.02l-2.626 1.51-2.626-1.51z"/>
          <path d="M263.204 183.163l2.626-1.51 2.626 1.51v3.02l-2.626 1.51-2.626-1.51z"/>
          <path stroke="#A3B1BF" stroke-width=".81"
                d="M263.204 183.163l2.626-1.51 2.626 1.51v3.02l-2.626 1.51-2.626-1.51zM201.942 33.194l2.626 1.51v3.021l-2.626 1.51-2.626-1.51v-3.02zM205.177 23.46l2.626 1.51v3.02l-2.626 1.51-2.626-1.51v-3.02z"/>
          <path d="M30.71 140.675H410.8" stroke="#A3B1BF" stroke-width="2.43"/>
          <path
              d="M128.737 148.382h281.659s-60.248 47.052-134.245 44.212c-73.996-2.839-98.258-15.008-116.858-21.916-18.6-6.909-55.37-9.722-80.881-4.043-25.511 5.678-48.107-18.253-48.107-18.253h98.432z"
              fill-opacity=".25" fill="#A3B1BF"/>
          <g transform="translate(2 66.851)">
            <ellipse stroke="#A3B1BF" stroke-width="2.43" fill-opacity=".25" fill="#A3B1BF" cx="35.583" cy="35.695"
                     rx="35.583" ry="35.695"/>
            <ellipse fill="#F0F2F5" cx="35.853" cy="35.965" rx="30.461" ry="30.557"/>
          </g>
          <path d="M41.627 98.896l38.817 11.357M67.91 131.751l-33.157-25.149" stroke="#A3B1BF" stroke-width=".81"/>
          <g fill="#A3B1BF">
            <path
                d="M70.31 119.831c-1.096 3.183.447 6.611 3.44 7.642 2.993 1.03 6.32-.721 7.416-3.904s-.448-6.61-3.44-7.641c-2.994-1.03-6.32.72-7.416 3.903m-2.422-.834c1.585-4.602 6.396-7.135 10.724-5.645 4.329 1.49 6.56 6.449 4.976 11.05-1.585 4.604-6.396 7.136-10.725 5.646-4.328-1.49-6.56-6.448-4.975-11.05l12.93 5.459"
                fill-opacity=".4"/>
            <path
                d="M65.796 118.038c-1.93 5.602.787 11.636 6.056 13.45 5.268 1.815 11.124-1.268 13.053-6.87 1.93-5.603-.787-11.637-6.056-13.451-5.268-1.814-11.124 1.268-13.053 6.87m-2.422-.833c2.418-7.023 9.758-10.887 16.362-8.613 6.604 2.274 10.01 9.838 7.592 16.86-2.419 7.024-9.76 10.887-16.363 8.613-6.604-2.273-10.01-9.837-7.591-16.86M33.648 100.545c-.872 2.53.293 5.235 2.597 6.028 2.303.793 4.886-.62 5.758-3.151.871-2.53-.294-5.235-2.598-6.028-2.303-.793-4.886.62-5.757 3.15m-1.06-.364c1.093-3.172 4.33-4.944 7.218-3.95 2.887.995 4.348 4.384 3.256 7.556-1.092 3.173-4.33 4.944-7.218 3.95-2.887-.994-4.348-4.384-3.255-7.556"/>
            <path
                d="M40.45 102.82l40.58-25.097 47.72 11.062-55.428 28.205.495 1.142L40.45 102.82zm-4.505 1.928l37.263 17.098c.812.289 1.543.052 2.004-.498l1.153 2.656 55.493-28.238 1.764 40.685c.046 1.059.702 1.913 1.597 2.22.294.102.614.144.948.116 1.344-.118 2.384-1.354 2.323-2.76l-2.803-64.639 3.695 1.273-2.395 6.957c-.272.79.11 1.638.853 1.894l13.86 4.773c.364.125.768.088 1.117-.103l9.903-5.416a4.23 4.23 0 0 1 .486 2.775c-.336 2.046-2.286 5.3-9.671 9.188a1.581 1.581 0 0 0-.697 2.024c.166.37.463.629.807.747.344.119.737.098 1.096-.092 6.875-3.619 10.677-7.5 11.3-11.538.576-3.723-1.807-6.042-1.909-6.139a1.364 1.364 0 0 0-1.61-.197l-10.406 5.692-11.945-4.113 1.903-5.526 1.704.587c1.264.435 2.663-.301 3.125-1.645.463-1.343-.186-2.785-1.45-3.22l-11.528-3.97c-.766-.263-1.62-.102-2.278.43a2.71 2.71 0 0 0-.993 2.216l.767 17.692L83.325 74.53 85.42 62.11c.074-.995-.633-1.835-1.582-1.874-.947-.04-1.774.736-1.847 1.732l-2.09 12.333-44.048 27.242a1.905 1.905 0 0 0-.888 1.669c.019.676.397 1.268.98 1.536z"/>
          </g>
          <path
              d="M122.093 124.044s-2.83 0-2.83 1.217 10.917 10.14 10.917 10.14l-8.087-11.357zM148.376 124.044s2.83 0 2.83 1.217-10.917 10.14-10.917 10.14l8.087-11.357zM116.922 128.687s-2.624 1.06-2.168 2.189c.455 1.128 13.92 5.312 13.92 5.312l-11.752-7.5zM152.029 129.517s2.624 1.063 2.17 2.191c-.455 1.129-13.91 5.3-13.91 5.3l11.74-7.491z"
              fill="#A3B1BF"/>
          <path
              d="M76.4 50.627c-1.426-.358-6.03-1.67-6.469.81-.356 2.015 2.58 8.016 5.661 8.924 6.874 2.029 14.557-.81 21.43 1.217 6.875 2.028 6.478-1.615 5.258-2.839-2.427-2.434-22.644-7.301-25.88-8.112z"
              stroke="#A3B1BF" stroke-width="2.43"/>
          <g transform="translate(348.684 67.257)">
            <ellipse stroke="#A3B1BF" stroke-width="2.43" fill="#DCE1E7" cx="35.583" cy="35.695" rx="35.583"
                     ry="35.695"/>
            <ellipse fill="#F0F2F5" cx="35.853" cy="35.965" rx="30.461" ry="30.557"/>
          </g>
          <path
              d="M366.901 84.293c-1.334.748-2.97 2.594-4.488 6.146-4.852 11.358-3.639 21.498-3.639 21.498s1.617-19.47 8.087-23.12M402.135 122.483c1.334-.748 2.97-2.594 4.487-6.146 4.853-11.357 3.64-21.498 3.64-21.498s-1.618 19.47-8.087 23.12"
              fill="#A3B1BF"/>
        </g>
      </svg>
    </div>
    <div class="ele-exception-content ele-text-left">
      <h1 class="ele-text">500</h1>
      <p class="ele-text-secondary">抱歉，服务器出错了</p>
      <div>
        <el-button type="primary" @click="$router.push('/')">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page500"
}
</script>

<style scoped>
.ele-exception {
  margin: 150px 0;
}

.ele-exception .ele-exception-img svg {
  max-width: 95%;
}

.ele-exception .ele-exception-img, .ele-exception .ele-exception-content {
  margin: 15px 30px;
  display: inline-block;
  vertical-align: middle;
}

.ele-exception .ele-exception-content > h1 {
  font-size: 72px;
  font-weight: 600;
  margin: 0 0 20px 0;
}

.ele-exception:not(.ele-exception-dark) .ele-exception-content > h1 {
  color: #515A6E;
}

.ele-exception .ele-exception-content > p {
  font-size: 20px;
  margin: 0 0 25px 0;
}

.ele-exception:not(.ele-exception-dark) .ele-exception-content > p {
  color: #808695;
}

/** 适配暗黑主题 */
.ele-exception-dark .ele-exception-img {
  opacity: .7;
}

/** 小屏幕样式 */
@media screen and (max-width: 768px) {
  .ele-exception {
    margin: 40px 0;
  }

  .ele-exception .ele-exception-img {
    margin: 0;
  }

  .ele-exception .ele-exception-img svg {
    max-height: 200px;
  }

  .ele-exception .ele-exception-content {
    text-align: center;
  }
}
</style>